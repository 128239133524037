import * as ol from "ol";
import {fromLonLat} from "ol/proj"
import { useContext, useEffect, useRef } from "react";
import TileLayer from 'ol/layer/Tile';
import {Cluster, OSM, Vector as VectorSource} from 'ol/source';
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import {Control } from 'ol/control';
import './map.css'
import { Context } from "../../context_provider";

function Map() {
  const { refreshMap, jobs, updateMap, map, createCluster, updateCreateCluster, updateRefreshList, updateJobs } = useContext(Context)
  const mapRef = useRef()

  useEffect(() => {
    if ( !refreshMap ) return;

    let options = {
      controls: [new ChangeDistanceControl()],
      view: new ol.View({
        center: [0, 0],
        zoom: 8,
        maxZoom: 15
      }),
      layers: [
        new TileLayer({ source: new OSM() })
      ],
      overlays: []
    }

    let mapObject = new ol.Map(options)
    mapObject.setTarget(mapRef.current)

    mapObject.on('click', (evt) => {
      let newJobs = window.jobs;
  
      if ( mapObject.hasFeatureAtPixel(evt.pixel) ) {
        const feature  = mapObject.getFeaturesAtPixel(evt.pixel)[0]
        const focuseds = feature.values_.features.map(jobFeature => jobFeature.values_.id)

        newJobs = newJobs.map(job => {
          if ( focuseds.includes(job.id) ) job.focused = true
          else job.focused = false
            
          return job
        })
      } else {
        newJobs = newJobs.map(job => {
          job.focused = false
            
          return job
        })
      }

      newJobs = newJobs.sort((a, b) => Number(b.focused) - Number(a.focused))

      updateJobs(newJobs)
      updateRefreshList(true)
      mapObject.getView().animate({
        center: evt.coordinate,
        duration: 1000
      })
    });

    updateMap(mapObject)

    if ( jobs.length > 0 ) updateCreateCluster(true)

    return () => mapObject.setTarget(undefined)
  }, [refreshMap])

  useEffect(() => {
    if ( !createCluster || !map || jobs.length < 1 ) return;

    let features = []
    const view   = map.getView()

    jobs.forEach(job => {
      features.push(new ol.Feature({
        id: job.id,
        geometry: new Point(fromLonLat([job.coordinates.lng, job.coordinates.lat]))
      }))
    })

    let totalLng = jobs.reduce((partialSum, job) => partialSum + job.coordinates.lng, 0)
    let totalLat = jobs.reduce((partialSum, job) => partialSum + job.coordinates.lat, 0)

    const source = new VectorSource({
      features: features
    })

    const clusterSource = new Cluster({
      source: source,
      distance: 40
    })

    const clusters = new VectorLayer({
      source: clusterSource,
      style: function(feature) {
        return new Style({
          image: new CircleStyle({
            radius: 20,
            stroke: new Stroke({
              color: '#fff'
            }),
            fill: new Fill({
              color: '#3399CC'
            })
          }),
          text: new Text({
            text: feature.get('features').length.toString(),
            fill: new Fill({
              color: '#fff'
            })
          })
        })
      }
    })

    map.addLayer(clusters)

    view.animate({
      center: fromLonLat([totalLng / jobs.length, totalLat / jobs.length]),
      duration: 1000
    })

    window.jobs = jobs;
  }, [createCluster])

  return (
    <div ref={mapRef} class="w-full h-[60vh]"></div>
  );
}

class ChangeDistanceControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const distanceLabel       = document.createElement('label')
    distanceLabel.for         = 'map-distance-control'
    distanceLabel.textContent = 'Change Distance Between Clusters'

    const distanceInput = document.createElement('input')
    distanceInput.id    = 'map-distance-control'
    distanceInput.type  = 'range'
    distanceInput.step  = 1
    distanceInput.min   = 20
    distanceInput.max   = 200
    distanceInput.value = 40

    const distanceField     = document.createElement('div')
    distanceField.className = 'field'

    distanceField.appendChild(distanceLabel)
    distanceField.appendChild(distanceInput)
    
    const element = document.createElement('div');
    element.className = 'control-area ol-unselectable ol-control';
    element.appendChild(distanceField);

    super({
      element: element,
      target: options.target,
    });

    distanceInput.addEventListener('change', this.handleDistanceControl.bind(this), false);
  }

  handleDistanceControl(event) {
    const layer = this.getMap().getAllLayers()[1]
    
    layer.getSource().setDistance(event.currentTarget.value)
  }
}

export default Map;
