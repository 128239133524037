import { useContext } from "react";
import { Context } from "../../context_provider";

function Processing(props) {
  const { processingStatus } = useContext(Context)

  if ( processingStatus ) {
    <div class="absolute top-0 right-0 left-0 bottom-0 bg-black/50 text-white flex justify-center items-center">
      Processing...
    </div>
  } else {
    return (
      <>{props.text}</>
    )
  }
}

export default Processing;