import Map from '../map'
import Data from '../data';

function MainScreen() {
  return (
    <main class="flex flex-col gap-4 py-5 px-8">
      <Map />
      <Data />
    </main>
  );
}

export default MainScreen;
