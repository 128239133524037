import { useContext } from 'react';
import { Context } from '../../context_provider';
import './data.css'

function Data() {
  const { jobs } = useContext(Context);

  return (
    <table id="data" class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
        <tr>
          <th scope="col">Job</th>
          <th scope="col">Job Type</th>
          <th scope="col">Customer</th>
          <th scope="col">Location</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        {jobs.length > 0 ? jobs.map(job => jobView(job)) : emptyJobList() }
      </tbody>
    </table>
  )
}

const jobView = job => {
  return (
    <tr data-id={job.id} key={job.id.toString()} class={job.focused && 'active'}>
      <td>{job.job_id}</td>
      <td>{job.job_type}</td>
      <td>{job.customer}</td>
      <td>{job.location}</td>
    </tr>
  )
}

const emptyJobList = () => {
  return (
    <tr>
      <td colSpan="5" class="text-center">Empty job list please upload a valid job csv file</td>
    </tr>
  )
}

export default Data;