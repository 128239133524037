import Navigation from './components/navigation'
import MainScreen from './components/main_screen'
import { ContextProvider } from './context_provider';

function App() {
  return (
    <ContextProvider>
      <div class="bg-gray-100 min-h-screen">
        <Navigation />
        <MainScreen />
      </div>
    </ContextProvider>
  );
}

export default App;
