import { useState, createContext } from "react";

export const Context = createContext();

export const ContextProvider = (props) => {
  const [jobs, setJobs] = useState([])
  const [processingStatus, setProcessingStatus] = useState(false)
  const [refreshMap, setRefreshMap] = useState(true)
  const [createCluster, setCreateCluster] = useState(false)
  const [refreshList, setRefreshList] = useState(false)
  const [map, setMap] = useState(null)

  return (
    <Context.Provider value={{
      jobs, processingStatus, refreshMap, refreshList, map, createCluster,
      updateJobs: a => setJobs(a), updateProcessingStatus: a => setProcessingStatus(a),
      updateRefreshMap: a => setRefreshMap(a), updateRefreshList: a => setRefreshList(a),
      updateMap: a => setMap(a), updateCreateCluster: a => setCreateCluster(a)
    }}>
      {props.children}
    </Context.Provider>
  )
}
