import { useContext, useRef } from 'react';
import './navigation.css';
import Processing from '../processing';
import Geocode from "react-geocode";
import { Context } from '../../context_provider';

Geocode.setApiKey("AIzaSyCelQ_QN4OYTq67ChLfWoJ5CX__2DPcoLI");

function Navigation() {
  const { 
    updateJobs, processingStatus, updateProcessingStatus, updateRefreshList, updateCreateCluster
  } = useContext(Context)

  const inputFileRef = useRef()

  const handleClick = event => {
    event.preventDefault()

    inputFileRef.current.click()
  }

  const handleUpload = event => {
    let reader = new FileReader()

    reader.addEventListener("loadend", () => {
      updateProcessingStatus(true)
      
      let csv  = reader.result.split("\n")
      let id   = 0
      let jobs = []
      
      for (let row of csv) {
        jobs.push({ id, ...exportLine(row), focused: false })
        id += 1
      }

      injectGeocode(jobs, updateJobs, updateProcessingStatus, updateRefreshList, updateCreateCluster)
    })

    reader.readAsText(event.target.files[0])
  }

  return (
    <nav id="navigation" class="bg-white shadow py-5 px-8">
      <div class="mx-auto flex justify-between">
        <div class="font-medium">
          <span class="text-indigo-700">Clusterer</span> - Buck.ai
        </div>
        <div>
          <button onClick={handleClick}>
            <Processing processing={processingStatus} text="Upload Data" />
          </button>
          <input type="file" ref={inputFileRef} accept=".csv" onChange={handleUpload} />
        </div>
      </div>
    </nav>
  )
}

const exportLine = row => {
  const cols     = row.split('","')
  const location = clearColumn(cols[3])

  return {
    job_id: clearColumn(cols[0]),
    job_type: clearColumn(cols[1]),
    customer: clearColumn(cols[2]),
    location
  }
}

const clearColumn = column => {
  if ( column === undefined ) return ""

  if ( column.startsWith('"') ) column = column.slice(1)
  if ( column.endsWith('\r') )  column = column.slice(0, -1)
  if ( column.endsWith('"') )   column = column.slice(0, -1)

  return column;
}

async function geocode(address) {
  const response = await Geocode.fromAddress(address);

  return response.results[0].geometry.location
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function injectGeocode(jobs, updateJobs, updateProcessing, updateRefreshList, updateCreateCluster) {
  jobs = await Promise.all(jobs.map(async (job, i) => {
    await sleep(i * 50)

    let coordinates = await geocode(job.location)

    return { ...job, coordinates }
  }))

  updateJobs(jobs)
  updateProcessing(false)
  updateRefreshList(true)
  updateCreateCluster(true)
}

export default Navigation;
